import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PlayerRow from './PlayerRow';
import AddPlayerModal from './AddPlayerModal';
import { FaPlus } from 'react-icons/fa';
import { loadPlayers, savePlayers } from '../utils/storage';

const Container = styled.div`
  padding: 20px;
  width: 100%;  // Ajoute cette ligne pour que le conteneur prenne toute la largeur
`;

const AddPlayerButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
`;

const Scoreboard = () => {
  const [players, setPlayers] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchPlayers = async () => {
      const loadedPlayers = await loadPlayers();
      setPlayers(Array.isArray(loadedPlayers) ? loadedPlayers : []);
    };

    fetchPlayers();
  }, []);

  useEffect(() => {
    savePlayers(players);
  }, [players]);

  const addPlayer = (newPlayer) => {
    setPlayers([...players, { ...newPlayer, id: Date.now() }]);
  };

  const incrementScore = (id) => {
    setPlayers(
      players
        .map(player =>
          player.id === id ? { ...player, score: player.score + 1 } : player
        )
        .sort((a, b) => b.score - a.score)
    );
  };

  const decrementScore = (id) => {
    setPlayers(
      players
        .map(player =>
          player.id === id ? { ...player, score: player.score - 1 } : player
        )
        .sort((a, b) => b.score - a.score)
    );
  };

  const editPlayer = (id) => {
    // Logique pour éditer un joueur
  };

  const deletePlayer = (id) => {
    setPlayers(players.filter(player => player.id !== id));
  };

  const backgroundColors = ['#FAD02E', '#FF5959', '#6BCB77', '#4D96FF', '#FF85C0'];

  return (
    <Container>
      {players.map((player, index) => (
        <PlayerRow
          key={player.id}
          player={player}
          incrementScore={incrementScore}
          decrementScore={decrementScore}
          editPlayer={editPlayer}
          deletePlayer={deletePlayer}
          backgroundColor={backgroundColors[index % backgroundColors.length]}
        />
      ))}
      <AddPlayerButton onClick={() => setShowModal(true)}>
        <FaPlus />
      </AddPlayerButton>
      {showModal && <AddPlayerModal onAddPlayer={addPlayer} onClose={() => setShowModal(false)} />}
    </Container>
  );
};

export default Scoreboard;
