import React from 'react';
import styled from 'styled-components';
import Scoreboard from './components/Scoreboard';

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f5f5f5;
  height: 100vh;
  font-family: 'Arial', sans-serif;
`;

const Title = styled.h1`
  font-size: 2.5em;
  color: #333;
  margin-bottom: 20px;
`;

function App() {
  return (
    <AppContainer>
      <Title>W.scoreboard</Title>
      <Scoreboard />
    </AppContainer>
  );
}

export default App;
