import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import Avatar from 'avataaars';
import { FaTrash, FaEdit } from 'react-icons/fa';
import { GrAddCircle, GrSubtractCircle } from "react-icons/gr";

const Row = styled(motion.div)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  margin-bottom: 10px;
  border-radius: 8px;
  width: 100%;
`;

const AvatarWrapper = styled.div`
  margin-right: 10px;
`;

const PlayerInfo = styled.div`
  display: flex;
  align-items: center;
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: 20px; 
`;

const ScoreContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.5em;
  font-weight: bold;
  margin-right: 20px;
  gap: 20px;
`;

const generateColorFromId = (id) => {
  const colors = ['#FAD02E', '#FF5959', '#6BCB77', '#4D96FF', '#FF85C0', '#FFD700', '#40E0D0', '#FF6347', '#4682B4'];
  return colors[id % colors.length];
};

const PlayerRow = ({ player, incrementScore, decrementScore, editPlayer, deletePlayer }) => {
  const backgroundColor = generateColorFromId(player.id); // Génération de la couleur en fonction de l'ID

  return (
    <Row
      backgroundColor={backgroundColor}
      layout
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
    >
      <PlayerInfo>
        <AvatarWrapper>
          <Avatar
            style={{ width: '40px', height: '40px' }}
            {...player.avatarConfig}
          />
        </AvatarWrapper>
        <span>{player.name}</span>
      </PlayerInfo>
      <Buttons>
        <ScoreContainer>
          <GrAddCircle onClick={() => incrementScore(player.id)} size={30} style={{ cursor: 'pointer', color: '#000000' }} />
          <span>{player.score}</span>
          <GrSubtractCircle onClick={() => decrementScore(player.id)} size={30} style={{ cursor: 'pointer', color: '#000000' }} />
        </ScoreContainer>
        <FaTrash onClick={() => deletePlayer(player.id)} style={{ cursor: 'pointer', color: '#FF4500' }} />
      </Buttons>
    </Row>
  );
};

export default PlayerRow;
