import localforage from 'localforage';

export const loadPlayers = async () => {
    const players = await localforage.getItem('players');
    return players || [];
  };

export const savePlayers = async (players) => {
  await localforage.setItem('players', players);
};
