import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Avatar from 'avataaars';

const Modal = styled.div`
  // Style pour ton modal
`;

const AvatarContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const Button = styled.button`
  padding: 10px;
  margin: 5px;
  font-size: 14px;
  cursor: pointer;
`;

const generateRandomConfig = () => {
  const avatarOptions = {
    topType: ['ShortHairShortFlat', 'ShortHairFrizzle', 'LongHairStraight', 'LongHairCurvy', 'NoHair'],
    hairColor: ['Brown', 'Blonde', 'Black', 'Red', 'SilverGray'],
    facialHairType: ['Blank', 'BeardMedium', 'BeardLight', 'MoustacheFancy'],
    clotheType: ['ShirtCrewNeck', 'Hoodie', 'BlazerShirt'],
    clotheColor: ['Blue03', 'Red', 'Gray02', 'PastelYellow', 'PastelGreen'],
    eyeType: ['Default', 'Happy', 'Squint', 'Surprised'],
    eyebrowType: ['Default', 'RaisedExcited', 'SadConcerned'],
    mouthType: ['Smile', 'Sad', 'Serious', 'ScreamOpen'],
    skinColor: ['Light', 'Tanned', 'Brown', 'DarkBrown', 'Pale'],
  };

  return {
    topType: avatarOptions.topType[Math.floor(Math.random() * avatarOptions.topType.length)],
    hairColor: avatarOptions.hairColor[Math.floor(Math.random() * avatarOptions.hairColor.length)],
    facialHairType: avatarOptions.facialHairType[Math.floor(Math.random() * avatarOptions.facialHairType.length)],
    clotheType: avatarOptions.clotheType[Math.floor(Math.random() * avatarOptions.clotheType.length)],
    clotheColor: avatarOptions.clotheColor[Math.floor(Math.random() * avatarOptions.clotheColor.length)],
    eyeType: avatarOptions.eyeType[Math.floor(Math.random() * avatarOptions.eyeType.length)],
    eyebrowType: avatarOptions.eyebrowType[Math.floor(Math.random() * avatarOptions.eyebrowType.length)],
    mouthType: avatarOptions.mouthType[Math.floor(Math.random() * avatarOptions.mouthType.length)],
    skinColor: avatarOptions.skinColor[Math.floor(Math.random() * avatarOptions.skinColor.length)],
  };
};

const AddPlayerModal = ({ onAddPlayer, onClose }) => {
  const [name, setName] = useState('');
  const [avatarConfig, setAvatarConfig] = useState(generateRandomConfig());

  useEffect(() => {
    setAvatarConfig(generateRandomConfig()); // Génère un avatar random par défaut
  }, []);

  const cycleOption = (option) => {
    const avatarOptions = {
      topType: ['ShortHairShortFlat', 'ShortHairFrizzle', 'LongHairStraight', 'LongHairCurvy', 'NoHair'],
      hairColor: ['Brown', 'Blonde', 'Black', 'Red', 'SilverGray'],
      facialHairType: ['Blank', 'BeardMedium', 'BeardLight', 'MoustacheFancy'],
      clotheType: ['ShirtCrewNeck', 'Hoodie', 'BlazerShirt'],
      clotheColor: ['Blue03', 'Red', 'Gray02', 'PastelYellow', 'PastelGreen'],
      eyeType: ['Default', 'Happy', 'Squint', 'Surprised'],
      eyebrowType: ['Default', 'RaisedExcited', 'SadConcerned'],
      mouthType: ['Smile', 'Sad', 'Serious', 'ScreamOpen'],
      skinColor: ['Light', 'Tanned', 'Brown', 'DarkBrown', 'Pale'],
    };

    const options = avatarOptions[option];
    const currentIndex = options.indexOf(avatarConfig[option]);
    const nextIndex = (currentIndex + 1) % options.length;
    setAvatarConfig({ ...avatarConfig, [option]: options[nextIndex] });
  };

  const handleRandomize = () => {
    setAvatarConfig(generateRandomConfig());
  };

  const handleAddPlayer = () => {
    onAddPlayer({
      name,
      avatarConfig,
      score: 0,
    });
    onClose();
  };

  return (
    <Modal>
      <input
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="Enter player name"
      />
      <AvatarContainer>
        <Avatar
          style={{ width: '100px', height: '100px' }}
          {...avatarConfig}
        />
      </AvatarContainer>

      <Button onClick={() => cycleOption('topType')}>Change Hairstyle</Button>
      <Button onClick={() => cycleOption('hairColor')}>Change Hair Color</Button>
      <Button onClick={() => cycleOption('facialHairType')}>Change Facial Hair</Button>
      <Button onClick={() => cycleOption('clotheType')}>Change Clothing</Button>
      <Button onClick={() => cycleOption('clotheColor')}>Change Clothing Color</Button>
      <Button onClick={() => cycleOption('eyeType')}>Change Eye Style</Button>
      <Button onClick={() => cycleOption('eyebrowType')}>Change Eyebrow Style</Button>
      <Button onClick={() => cycleOption('mouthType')}>Change Mouth Style</Button>
      <Button onClick={() => cycleOption('skinColor')}>Change Skin Color</Button>
      <Button onClick={handleRandomize}>Randomize Avatar</Button> {/* Bouton pour randomizer l'avatar */}

      <button onClick={handleAddPlayer}>Add Player</button>
      <button onClick={onClose}>Close</button>
    </Modal>
  );
};

export default AddPlayerModal;
